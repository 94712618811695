import React from "react";

class LastNameField extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <label>
                    Last Name:
                    <input type="text" value={this.props.value} onChange={this.props.onChange} />
                </label>
            </div>
        )
    }
}

export default LastNameField;
