import React from "react";

class PasswordField extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <label>
                    Password:
                    <input type="password" value={this.props.value} onChange={this.props.onChange} />
                </label>
            </div>
        )
    }
}

export default PasswordField;
