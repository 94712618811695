import React from "react";
import LoginForm from "../components/forms/LoginForm";
import { Link } from "react-router-dom";


class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <h3>Login</h3>
                <p>
                    Don't have an account yet? If your email is registered with the family, you can click <Link to="/create">here</Link> to make one.
                </p>
                <LoginForm />
            </div>
        )
    }
}

export default Login;
