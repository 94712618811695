import React from "react";
import EmailField from "../fields/EmailField";
import PasswordField from "../fields/PasswordField";

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
        };

        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
    }

    handleChangeEmail(event) {
        this.setState({
            ...this.state,
            email: event.target.value,
        });
    }

    handleChangePassword(event) {
        this.setState({
            ...this.state,
            password: event.target.value,
        });
    }

    handleLoginSubmit(event) {
        event.preventDefault();
        if (this.inputValid()) {
            alert(`submitted email: ${this.state.email}`);
        } else {
            alert('invalid input');
        }
    }

    inputValid() {
        if (
            this.state.email !== '' &&
            this.state.password !== ''
        ) {
            return true;
        }
        return false;
    }

    render() {
        return (
            <div>
                <form onSubmit={this.handleLoginSubmit}>
                    <EmailField value={this.state.email} onChange={this.handleChangeEmail} />
                    <PasswordField value={this.state.password} onChange={this.handleChangePassword} />
                    <input type="submit" value="Submit" />
                </form>
            </div>
        )
    }
}

export default LoginForm;
