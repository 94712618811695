const DEFAULT_HOST = "https://api.neufville.family"

export function apiStrings() {
    return fetch(`${DEFAULT_HOST}/strings`).then(res => res.json());
}

export function apiCreateUser(email, fullname) {
    return fetch(`${DEFAULT_HOST}/create-user`, {
        method: 'POST',
        body: JSON.stringify({
            email,
            fullname,
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    }).then(res => res.json());
}