import React from "react";
import CreateAccountForm from "../components/forms/CreateAccountForm";
import { Link } from "react-router-dom";

class CreateAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <h3>Enter the following information to create your account.</h3>
                
                <p>
                    You can only make an account if your email is already in the contact list. If you have any issues or your email is not registered yet, please reach out to ramsey.opp@gmail.com.
                </p>
                <p>
                    Already have an account? Login <Link to="/login">here.</Link>
                </p>
                <CreateAccountForm />
            </div>
        )
    }
}

export default CreateAccount;
