import React from "react";
import '../styles/welcome.css';
import { apiWelcome } from "../api/api";

class Welcome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {welcomeString: ''};
    }

    componentDidMount() {
        this.props.strings.then(data => {
            this.setState({
                ...this.state,
                welcomeString: data.welcome.EN,
            });
        });
    }

    render() {
        return (
          <div className="Welcome">
            <h3>Welcome!</h3>
            <p>
              {this.state.welcomeString}
            </p>
          </div>
        );
    }
}

export default Welcome;
